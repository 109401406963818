<template>
  <el-dialog v-dialogDrag title="行业类目" :visible.sync="visible" width="600px">
    <el-form :model="form" ref="form" :rules="rules" style="padding:20px" label-width="140px">
      <el-form-item label="顶级行业类目名称" prop="industryName">
        <el-input v-model="form.industryName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
	value: {
		type: Object,
		default: () => {
			return {}
		},
	}
  },
  data() {
    return {
      form: {
        industryName: "",
        parentCode: "0",
      },
      rules: {
        industryName: [
          {
            required: true,
            message: "顶级行业类目名称必填",
          },
        ],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },

  watch: {
	  value(n, o) {
	  	if (this.value) {
	  		this.form = JSON.parse(JSON.stringify(this.value));
	  	}else{
			this.form={
			  industryName: "",
			  parentCode: "0",
			}
		}
	  }
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
			if(this.form.id){
				this.update()
			}else{
				this.save()
			}
        }
      });
    },
	// 新增
	async save(){
		let res = await this.$post("/platform-config/industry", this.form);
		if (res && res.code === 0) {
		  this.$message.success("添加成功");
		  this.$emit("submit");
		  this.$emit("update:show", false);
		  this.$ref.form.validate()
		}
	},
	// 删除
	async update(){
		let res = await this.$put("/platform-config/industry", this.form);
		if (res && res.code === 0) {
		  this.$message.success("修改成功");
		  this.$emit("submit");
		  this.$emit("update:show", false);
		  this.$ref.form.validate()
		}
	},
  },
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: 350px;
}
</style>